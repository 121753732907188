@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Mea+Culpa&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,100..700;1,100..700&family=Oswald:wght@200..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,100..700;1,100..700&family=Libre+Bodoni:ital,wght@0,400..700;1,400..700&family=Oswald:wght@200..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,100..700;1,100..700&family=Libre+Bodoni:ital,wght@0,400..700;1,400..700&family=Oswald:wght@200..700&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Georgian:wght@100..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Georgian:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bona+Nova+SC:ital,wght@0,400;0,700;1,400&family=Noto+Serif+Georgian:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Domine:wght@400..700&display=swap');
.domine{
  font-family: 'Domine', serif;
}
.bona-novo{
  font-family: 'Bona Nova SC', serif;
}
.noto-serif{
  font-family: 'Noto Serif Georgian', serif;
}
.allura{
  font-family: 'Allura', cursive;
}
.georgian-serif{
    font-family: 'Noto Serif Georgian', serif;
}
.railway{
    font-family: 'Raleway', sans-serif;
}
.roboto-condensed{
    font-family: 'Roboto Condensed', sans-serif;
}
.bodoni-libre{
    font-family: 'Libre Bodoni', serif;
}
.josefin{
    font-family: 'Josefin Slab', serif;
}
.oswald{
    font-family: 'Oswald', sans-serif;
}
.mea-culpa-regular{
    font-family: 'Mea Culpa', cursive;
}
.Merriweather{
    font-family: 'Merriweather', serif;
}
.carousel-container-text{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
}
.carousel-container-img{
    width: -webkit-fill-available;
}
.info-container{
height:30%;
background-color: rgb(225, 225, 225);
}

.expand {
    transition: all 0.3s ease-in-out;
  }
  
  .expand-hidden {
    opacity: 0;
    transform: scale(0);
  }
  
  .expand-visible {
    opacity: 1;
    transform: scale(1);
  }
  
@media screen and (max-width: 768px) {
    .carousel-container-text{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-around;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .packagesCard{
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
        justify-content: flex-start;
        flex-wrap: nowrap;
        gap: 30px;
        
    }
}

blockquote {
    font-family: Georgia, serif;
    font-size: 18px;
    font-style: italic;
    line-height: 1.45;
    position: relative;
    color: #383838;
}

blockquote:before {
    display: block;
    padding-left: 10px;
    content: "\201C";
    font-size: 80px;
    position: absolute;
    left: -20px;
    top: -20px;
    color: #7a7a7a;
}

/* WhyUs.css */

.why-us {
    background-image: url('../public/bg.png');
  }
  
  @media (min-width: 768px) {
    .why-us {
      background-image: url('../public/bg.png');
    }
  }
  
  @media (max-width: 767px) {
    .why-us {
      background-image: url('../public/bg-mob.png');
    }
  }


  #quote-box {
    clip-path: polygon(0 0, 100% 0, 100% 50%, 0 50%); /* Create a triangle shape */
  }

  
.navigation__checkbox {
  display: none;
}

.navigation__button {
  position: absolute;
  top: 5px;
  right: 50px;
  height: 1rem;
  width: 1rem;
  color:#000000;
  text-align: center;
  background-color: transparent;
  border-radius: 50%;
  z-index: 300;
  /* box-shadow: 0 1rem 3rem rgb(0, 0, 0); */
  cursor: pointer;
}

/* @media screen  and (min-width: 768px) {
  .navigation__button {
      top: 0.5rem;
      right: 0.5rem;
      height: 2rem;
      width: 2rem;
  }
} */

.navigation__background {
  position: fixed;
  top: -2.5rem;
  right: -1.5rem;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background: #FEDEBE;
  background-size: cover;
  background-position: center;
  z-index: 100;
  transition: all 800ms cubic-bezier(0.86, 0, 0.07, 1);
}

@media screen and (min-width: 768px) {
  .navigation__background {
      top: 4.5rem;
      right: 4.5rem;
      height: 6rem;
      width: 6rem;
  }
}

.navigation__nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  opacity: 0;
  width: 0;
  visibility: hidden;
  z-index: 200;
  transition: all 800ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.navigation__list {
  /*@include center;*/
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  list-style: none;
}

.navigation__item {
  margin: 1rem;
}

.navigation__link:link,
.navigation__link:visited {
  display: inline-block;
  padding: 1rem 2rem;
  text-transform: uppercase;
  color: #000000;
  font-size: 2.4rem;
  text-decoration: none;
  transition: all .2s;
}

@media(min-width: 768px) {
  .navigation__link:link,
  .navigation__link:visited {
      font-size: 3.8rem;
  }
}

.navigation__link span {
  margin-right: 1.5rem;
  display: inline-block;
}

.navigation__link:hover {
  color: #C1D0D0;
  transform: scale(1.1);
}

.navigation__checkbox:checked~.navigation__background {
  transform: scale(80)
}

.navigation__checkbox:checked~.navigation__nav {
  width: 100%;
  visibility: visible;
  opacity: 1;
}

.navigation__icon {

  position: relative;
  margin-top: 2.5rem;
}

@media screen and (min-width: 768px) {
  .navigation__icon {
      margin-top: 3.5rem;
  }
}

.navigation__icon,
.navigation__icon::before,
.navigation__icon::after {
  display: inline-block;
  width: 2.2rem;
  height: 2px;
  background-color: #ebebeb;
}

@media (min-width: 768px) {
  .navigation__icon,
  .navigation__icon::before,
  .navigation__icon::after {
      width: 3rem;
  }
}

.navigation__icon::before,
.navigation__icon::after {
  content: '';
  position: absolute;
  left: 0;
  transition: all 200ms;
}

.navigation__icon::before {
  top: -.8rem;
}

.navigation__icon::after {
  top: .8rem;
}

.navigation__button:hover .navigation__icon::before {
  top: -1rem;
}

.navigation__button:hover .navigation__icon::after {
  top: 1rem;
} 

.navigation__checkbox:checked+.navigation__button .navigation__icon {
  background-color: transparent;
}

.navigation__checkbox:checked+.navigation__button .navigation__icon::before {
  top: 0;
  transform: rotate(135deg);
}

.navigation__checkbox:checked+.navigation__button .navigation__icon::after {
  top: 0;
  transform: rotate(-135deg);
}
